export interface Video {
  id: string;
  text: string;
  youtubeLink?: string; // making it optional
  image?: string; // making it optional
}

export interface Song {
  id: string;
  text: string;
  link?: string; // making it optional
  logo?: string; // making it optional
}

// productData.js
export const products = [
  {
    id: "2",
    text: "Indieclimb",
    front: "/images/black-shirt-blue-logo.png",
    back: "/images/shirt-ida.png",
    productId: 2,
    // Additional product details can be added here
  },
  {
    id: "3",
    text: "Dolce Deux",
    front: "/images/black-shirt-deux.png",
    back: "/images/black-shirt-blue-logo-back.png",
    productId: 3,
    // Additional product details can be added here
  },
  {
    id: "4",
    text: "Reverse Stargirl",
    front: "/images/shirt-ida.png",
    back: "/images/black-shirt-ida.png",
    productId: 4,
    // Additional product details can be added here
  },

  {
    id: "5",
    text: "Stargirl",
    front: "/images/black-shirt-ida.png",
    back: "/images/shirt-ida.png",
    productId: 5,
    // Additional product details can be added here
  },
  // Add more products here...
];

export const videoData: Video[] = [
  {
    id: "1",
    text: "Black Horses ft. Lucy La Dusk",
    youtubeLink: "https://www.youtube.com/watch?v=tEVdHa1QCLg",
    image: "./images/thumbnails/BLACK_HORSES.jpg",
  },
  {
    id: "2",
    text: "The Bear",
    youtubeLink: "https://www.youtube.com/watch?v=hnJaDVuQH30",
    image: "./images/thumbnails/THE_BEAR.jpg",
  },
  {
    id: "3",
    text: "Dreamboy",
    youtubeLink: "https://www.youtube.com/watch?v=4E1nka9lslU",
    image: "./images/thumbnails/DREAMBOY.jpg",
  },
];

export const songData: Song[] = [
  {
    id: "0",
    text: "LET ME GO",
    link: "https://www.youtube.com/watch?v=8dpDeWyM1R8",
    logo: "./images/COVERINS.jpg",
  },
  {
    id: "1",
    text: "Black Horses",
    link: "https://www.youtube.com/watch?v=tEVdHa1QCLg",
    logo: "./images/thumbnails/BLACK_HORSES.jpg",
  },
  {
    id: "2",
    text: "The Bear",
    link: "https://www.youtube.com/watch?v=hnJaDVuQH30",
    logo: "./images/the_bear.jpg",
  },
  {
    id: "3",
    text: "Dreamboy",
    link: "https://www.youtube.com/watch?v=4E1nka9lslU",
    logo: "./images/dreamboy.jpg",
  },
];

// export const mediaItems = {
//   item1: {
//     url: "../../images/placeholder.jpg",
//     altText: "Ida Amalie Louise Rynning Malvik in the intro of 'LET ME GO'",
//     ratio: "Horizontal",
//     pxs: "1920x1080",
//     mediaType: "image",
//   },
//   item2: {
//     url: "../../images/placeholder.jpg",
//     altText:
//       "Ida Amalie Louise Rynning Malvik and Anne Marie Passianoto Zugaib in the intro of 'LET ME GO'",
//     ratio: "Horizontal",
//     pxs: "1920x1080",
//     mediaType: "image",
//   },
//   item3: {
//     url: "../../images/placeholder.jpg",
//     altText:
//       "Ida Amalie Louise Rynning Malvik and Anne Marie Passianoto Zugaib in the intro of 'LET ME GO'",
//     ratio: "Horizontal",
//     pxs: "1920x1080",
//     mediaType: "image",
//   },
//   item4: {
//     url: "../../images/placeholder.jpg",
//     altText:
//       "Ida Amalie Louise Rynning Malvik and Anne Marie Passianoto Zugaib in the intro of 'LET ME GO'",
//     ratio: "Horizontal",
//     pxs: "1920x1080",
//     mediaType: "image",
//   },
//   item5: {
//     url: "../../images/placeholder.jpg",
//     altText:
//       "Ida Amalie Louise Rynning Malvik, Anne Marie Passianoto Zugaib and Emma Effie Morrison Lund in the carscene in 'LET ME GO'",
//     ratio: "Horizontal",
//     pxs: "1920x1080",
//     mediaType: "image",
//   },
//   item6: {
//     url: "../../images/placeholder.jpg",
//     altText: "Ida Amalie Louise Rynning Malvik in the carscene in 'LET ME GO'",
//     ratio: "Horizontal",
//     pxs: "1920x1080",
//     mediaType: "image",
//   },
//   item7: {
//     url: "../../images/placeholder.jpg",
//     altText: "Ida Amalie Louise Rynning Malvik in the carscene in 'LET ME GO'",
//     ratio: "Horizontal",
//     pxs: "1920x1080",
//     mediaType: "image",
//   },
//   item8: {
//     url: "../../images/placeholder.jpg",
//     altText: "Ida Amalie Louise Rynning Malvik in the carscene in 'LET ME GO'",
//     ratio: "Horizontal",
//     pxs: "1920x1080",
//     mediaType: "image",
//   },
//   item9: {
//     url: "../../images/placeholder.mp4",
//     altText: "Ida Amalie Louise Rynning Malvik in the carscene in 'LET ME GO'",
//     ratio: "Vertical",
//     pxs: "1920x1080",
//     thumbnail: "../../images/placeholder.jpg",
//     mediaType: "video",
//   },
// };

export const mediaItems = {
  item1: {
    url: "../../images/STORE_ida-intro.jpg",
    altText: "Ida Amalie Louise Rynning Malvik in the intro of 'LET ME GO'",
    ratio: "Horizontal",
    pxs: "1920x1080",
    mediaType: "image",
  },
  item2: {
    url: "../../images/STORE_ida-anne.jpg",
    altText:
      "Ida Amalie Louise Rynning Malvik and Anne Marie Passianoto Zugaib in the intro of 'LET ME GO'",
    ratio: "Horizontal",
    pxs: "1920x1080",
    mediaType: "image",
  },
  item3: {
    url: "../../images/STORE_ida-anne0.jpg",
    altText:
      "Ida Amalie Louise Rynning Malvik and Anne Marie Passianoto Zugaib in the intro of 'LET ME GO'",
    ratio: "Horizontal",
    pxs: "1920x1080",
    mediaType: "image",
  },
  item4: {
    url: "../../images/STORE_ida-anne1.jpg",
    altText:
      "Ida Amalie Louise Rynning Malvik and Anne Marie Passianoto Zugaib in the intro of 'LET ME GO'",
    ratio: "Horizontal",
    pxs: "1920x1080",
    mediaType: "image",
  },
  item5: {
    url: "../../images/CAR_NIGHT-emma-anne-ida2.jpg",
    altText:
      "Ida Amalie Louise Rynning Malvik, Anne Marie Passianoto Zugaib and Emma Effie Morrison Lund in the carscene in 'LET ME GO'",
    ratio: "Horizontal",
    pxs: "1920x1080",
    mediaType: "image",
  },
  item6: {
    url: "../../images/CAR_NIGHT-ida.jpg",
    altText: "Ida Amalie Louise Rynning Malvik in the carscene in 'LET ME GO'",
    ratio: "Horizontal",
    pxs: "1920x1080",
    mediaType: "image",
  },
  item7: {
    url: "../../images/CAR_NIGHT-ida0.jpg",
    altText: "Ida Amalie Louise Rynning Malvik in the carscene in 'LET ME GO'",
    ratio: "Horizontal",
    pxs: "1920x1080",
    mediaType: "image",
  },
  item8: {
    url: "../../images/CAR_NIGHT-ida1.jpg",
    altText: "Ida Amalie Louise Rynning Malvik in the carscene in 'LET ME GO'",
    ratio: "Horizontal",
    pxs: "1920x1080",
    mediaType: "image",
  },
  item9: {
    url: "../../images/videos/CAR_NIGHT_TEASER-ida.mp4",
    altText: "Ida Amalie Louise Rynning Malvik in the carscene in 'LET ME GO'",
    ratio: "Vertical",
    pxs: "1920x1080",
    thumbnail: "../../images/posters/POSTER.jpg",
    mediaType: "video",
  },

  item10: {
    url: "../../images/videos/CAR_NIGHT_TEASER-ida_horizintal.mp4",
    altText: "Ida Amalie Louise Rynning Malvik in the carscene in 'LET ME GO'",
    ratio: "Horizontal",
    pxs: "1920x1080",
    thumbnail: "../../images/posters/POSTER_HORIZONTAL.jpg",
    mediaType: "video",
  },
  item15: {
    url: "../../images/videos/LETMEGO_TEASER_1-v.mp4",
    altText: "Ida Amalie Louise Rynning Malvik in the carscene in 'LET ME GO'",
    ratio: "Vertical",
    pxs: "1920x1080",
    thumbnail: "../../images/posters/CINEPOSTER_4_5_VERTICAL.jpg",
    mediaType: "video",
  },

  item11: {
    url: "../../images/profile.jpg",
    altText: "Ida Amalie Louise Rynning Malvik in the carscene in 'LET ME GO'",
    ratio: "Horizontal",
    pxs: "1920x1080",
    mediaType: "image",
  },
  item12: {
    url: "../../images/profile-3.jpg",
    altText: "Ida Amalie Louise Rynning Malvik in the carscene in 'LET ME GO'",
    ratio: "Vertical",
    pxs: "1920x1080",
    mediaType: "image",
  },
  item13: {
    url: "../../images/martin-profile.jpg",
    altText: "Indieclimb",
    ratio: "Horizontal",
    pxs: "1920x1080",
    mediaType: "image",
  },
  item14: {
    url: "../../images/martin-profile-2.jpg",
    altText: "Indieclimb",
    ratio: "Horizontal",
    pxs: "1920x1080",
    mediaType: "image",
  },
};
