import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

// Define an object to map language codes to their translation files
const translationFiles = {
  ar: require("./locales/ar/translation.json"),
  en: require("./locales/en/translation.json"),
  no: require("./locales/no/translation.json"),
  es: require("./locales/es/translation.json"),
  fr: require("./locales/fr/translation.json"),
  ch: require("./locales/ch/translation.json"),
  dk: require("./locales/dk/translation.json"),
  se: require("./locales/se/translation.json"),
  it: require("./locales/it/translation.json"),
  fa: require("./locales/fa/translation.json"),
  bn: require("./locales/bn/translation.json"),
  dz: require("./locales/dz/translation.json"),
  ms: require("./locales/ms/translation.json"),
  km: require("./locales/km/translation.json"),
  zh: require("./locales/zh/translation.json"),
  el: require("./locales/el/translation.json"),
  ka: require("./locales/ka/translation.json"),
  hi: require("./locales/hi/translation.json"),
  id: require("./locales/id/translation.json"),
  he: require("./locales/he/translation.json"),
  ja: require("./locales/ja/translation.json"),
  kk: require("./locales/kk/translation.json"),
  ky: require("./locales/ky/translation.json"),
  mn: require("./locales/mn/translation.json"),
  my: require("./locales/my/translation.json"),
  ne: require("./locales/ne/translation.json"),
  ko: require("./locales/ko/translation.json"),
  si: require("./locales/si/translation.json"),
  th: require("./locales/th/translation.json"),
  vi: require("./locales/vi/translation.json"),
  mk: require("./locales/mk/translation.json"),
  sq: require("./locales/sq/translation.json"),
  ca: require("./locales/ca/translation.json"),
  be: require("./locales/be/translation.json"),
  bs: require("./locales/bs/translation.json"),
  bg: require("./locales/bg/translation.json"),
  hr: require("./locales/hr/translation.json"),
  cs: require("./locales/cs/translation.json"),
  et: require("./locales/et/translation.json"),
  fi: require("./locales/fi/translation.json"),
  hu: require("./locales/hu/translation.json"),
  is: require("./locales/is/translation.json"),
  lv: require("./locales/lv/translation.json"),
  lt: require("./locales/lt/translation.json"),
  mt: require("./locales/mt/translation.json"),
  nl: require("./locales/nl/translation.json"),
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    returnObjects: true,
    resources: translationFiles, // Use the translationFiles object
    lng: "en", // default language to use
    fallbackLng: "en", // fallback to English if the language is not available
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
