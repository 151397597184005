import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./SocialMediaPost.module.scss";
import { Header } from "../Header/Header.component";

type SocialMediaPostProps = {
  profilePicture: string;
  username: string;
  timestamp: string;
  contentImages: string[];
  likes: number;
  description: string | string[];
  name: string;
  currentProject: string;
  translations: {
    likes: string;
    more: string;
    linkInBio: string;
    title: string;
  };
};

const SocialMediaPost: React.FC<SocialMediaPostProps> = ({
  profilePicture,
  username,
  timestamp,
  contentImages,
  likes,
  description,
  name,
  translations,
  currentProject,
}) => {
  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
  };

  const [isExpanded, setIsExpanded] = useState(false);

  // Helper function to toggle description expansion
  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
  };

  // Function to render the description based on the isExpanded state

  let totalCharCount = 0;
  let firstParagraph = "";
  let additionalParagraphs = [];

  if (Array.isArray(description)) {
    totalCharCount = description.reduce((acc, item) => acc + item.length, 0);
    firstParagraph = description[0];
  } else {
    totalCharCount = description.length;
    firstParagraph = description;
  }
  const renderDescription = () => {
    const shouldShowMore = totalCharCount > 100 && !isExpanded;

    const renderRemainingContent = () => {
      if (Array.isArray(description)) {
        return description
          .slice(1)
          .filter((text) => text.length > 0)
          .map((para, index) => (
            <p key={index}>{isExpanded ? para : para.substring(0, 100)}</p>
          ));
      }
    };

    return (
      <>
        {shouldShowMore && (
          <button onClick={toggleDescription} className={styles.showMoreButton}>
            {translations.more.toLowerCase()}
          </button>
        )}
        {!shouldShowMore && renderRemainingContent()}
      </>
    );
  };

  return (
    <>
      <Header
        text={translations.title}
        size="h2"
        textAlign="center"
        marginTop="10rem"
        marginBottom="2rem"
      />
      <div className={styles.postContainer}>
        <header className={styles.postHeader}>
          <img
            src={profilePicture}
            alt="profile"
            className={styles.profilePic}
          />
          <div className={styles.headerInfo}>
            <p className={styles.username}>
              {username}
              <img
                src={"/images/icons/verified.svg"}
                alt="profile"
                style={{
                  height: "14px",
                  width: "14px",
                  marginBottom: "-2px",
                  marginLeft: "5px",
                }}
              />
            </p>
            <p className={styles.timestamp}>{translations.linkInBio}</p>
          </div>
          <img
            src={"/images/icons/More.png"}
            alt="profile"
            style={{ marginLeft: "auto", height: "20px", width: "20px" }}
          />
        </header>
        <div className={styles.postContent}>
          <Slider {...sliderSettings}>
            {contentImages &&
              contentImages.map((image, index) => (
                <div key={index}>
                  {image.endsWith(".mp4") ? (
                    <video src={image} width={"100%"} controls autoPlay />
                  ) : (
                    <img
                      src={"/images/projects/" + currentProject + "/" + image}
                      alt={`content-${index}`}
                      style={{ height: "10px !important" }}
                    />
                  )}
                </div>
              ))}
          </Slider>
        </div>
        <div className={styles.postFooter}>
          <div className={styles.icons}>
            <img src="/images/icons/Like.png" alt="" />
            <img src="/images/icons/Comment.png" alt="" />
            <img src="/images/icons/Share.png" alt="" />
            <img
              src="/images/icons/Bookmark.png"
              style={{ marginLeft: "auto" }}
              alt=""
            />
          </div>
          <p className={styles.likes}>
            {likes.toLocaleString()} {translations.likes.toLowerCase()}
          </p>
          <p className={styles.description}>
            <b>{username} </b>
            {isExpanded ||
            !Array.isArray(description) ||
            description[0].length <= 100
              ? firstParagraph
              : `${firstParagraph.substring(0, 100)}... `}
            {renderDescription()}
          </p>
        </div>
      </div>
    </>
  );
};

export default SocialMediaPost;
