import React from "react";
import styles from "./SongSection.module.scss";
import { MediaItem } from "../SocialMedia/SocialMedia.component";
import { Header } from "../Header/Header.component";
import { Paragraph } from "../Paragraph/Paragraph.component";
import { RecordSpinner } from "../RecordSpinner/RecordSpinner.component";
import { colors } from "@mui/material";

/**
 * Props for the SongSection component.
 */
interface SongSectionProps {
  link: string;
  title: string;
  description: string;
  date: string;
  imgURL: string;
  releaseOn: string;
  platforms: MediaItem[];
  releaseDateTitle: string;
  about: string;
  colors?: {
    primary: string;
    secondary: string;
    tertiary: string;
  };
  albumName: string;
  albumCover: string;
  audio_src: string;
}

export const SongSection: React.FC<SongSectionProps> = ({
  link,
  title,
  description,
  date,
  imgURL,
  releaseOn,
  platforms,
  releaseDateTitle,
  about,
  colors,
  albumName,
  albumCover,
  audio_src,
}) => {
  const platformNames = platforms.map((item) => item.name).join(", ");

  return (
    <div className={styles.songSection}>
      <div className={styles.songSectionInfo}>
        <div className={styles.songSectionInfoDescription}>
          <Header
            text={title}
            size="h2"
            isCenterPhone="yes"
            marginBottom=".5rem"
          />

          <Paragraph
            text={`${releaseOn} ${platformNames}`}
            size="xsmall"
            isCenterPhone="yes"
            italic
          />
          <Paragraph
            size="xsmall"
            italic
            isCenterPhone="yes"
            marginBottom="2rem"
          >
            <strong>
              {releaseDateTitle} {date}
            </strong>
          </Paragraph>

          <Paragraph text={description} size="small" isCenterPhone="yes" />
        </div>
      </div>

      <div className={styles.songSectionImg}>
        <div className={styles.songSectionImgBackground}>
          <img src={imgURL} alt="" />
        </div>
        <RecordSpinner
          colors={colors}
          albumName={albumName}
          audioSrc={audio_src}
          img={"/images/cds/cvr_template2.png"}
          cover={albumCover}
        />
        {/* <iframe
          width="500"
          height="500"
          title="soundcloud"
          allow="autoplay"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1598690229%3Fsecret_token%3Ds-vzoCB4KOKMA&color=%231107ff&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false&visual=true"
        ></iframe> */}
      </div>
    </div>
  );
};
