import React, { CSSProperties, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "./epk.module.scss";
import { SecondaryHero } from "../../components/SecondaryHero/SecondaryHero.component";
import { PressRelease } from "../../components/PressRelease/PressRelease.component";
import { SocialMedia } from "../../components/SocialMedia/SocialMedia.component";
import { MEDIA_CONFIG, SOCIAL_MEDIA_CONFIG } from "../../mediaConfig";
import i18n from "../../i18next";
import { SongSection } from "../../components/SongSection/SongSection.component";
import { ImagesSection } from "../../components/ImagesSection/ImagesSection.component";
import { BiographySection } from "../../components/BiographySection/BiographySection.component";
import { Shocker } from "../../components/Shocker/Shocker.component";
import { PROJECT_DETAILS } from "../../_config/_project_details";
import { DUMMY_MAGAZINE, DUMMY_TRANTSLATION } from "../../_config/_dummy";
import SocialMediaPost from "../../components/SocialMediaPost/SocialMediaPost.component";
import { ILanguageDefaults } from "../../_config/_interfaces";
import projects from "../../_config/projects.json";
export interface CustomStyle extends CSSProperties {
  "--main-color"?: string;
  "--second-color"?: string;
  "--third-color"?: string;
}
interface Project {
  name: string;
  emoji: string;
  assets: {
    logo: string;
    profile: string;
    // define assets structure here
  };
  // ... other properties
}

interface Projects {
  [key: string]: any;
}

interface Magazine {
  name: string;
  genre?: string;
  website?: string;
  emails?: string[];
}

interface Country {
  magazines: { [key: string]: Magazine };
}

interface LanguageObject {
  [key: string]: any;
}

export const EPK: React.FC = () => {
  const { lang } = useParams();
  const projectName = useParams().projectName || "let-me-go";
  const magazineId = useParams().magazineId || "defaultMagazineId";
  const [magazineData, setMagazineData] = useState<LanguageObject>({});
  const [selectedMagazine, setSelectedMagazine] =
    useState<LanguageObject | null>(null);
  const [settings, setSettings] = useState<LanguageObject | null>(null);

  const [projectData, setProjectData] = useState<LanguageObject | null>(null);
  const [languageDefaults, setLanguageDefaults] =
    useState<ILanguageDefaults>(DUMMY_TRANTSLATION);

  useEffect(() => {
    console.log(projectData);
  }, [projectData]);

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
    }

    const loadMagazineData = async () => {
      try {
        // Dynamically import the translation file based on the selected language
        const safeLang = lang ?? "en";
        const translation = await import(
          `../../locales/${safeLang}/translation.json`
        );

        // Log the loaded translation object to verify its structure
        console.log("Loaded translation:", translation);
        // Access the magazine data from the translation object
        const magazineData = safeLang && translation[safeLang]?.magazines;
        const languageDefaults =
          translation[safeLang]?.languageDefaults ?? DUMMY_TRANTSLATION;

        // Check if magazine data exists
        if (!magazineData) {
          console.error("Magazines data not found");
          throw new Error("Magazines data not found");
        }

        // Log the magazine data for debugging
        console.log("Magazine data:", magazineData);
        setMagazineData(magazineData);
        setLanguageDefaults(languageDefaults);
        console.log("Language data:", languageDefaults);

        // Attempt to find the matched magazine
        const matchedMagazine = findMagazine(magazineData, magazineId);

        // Check if a matched magazine was found
        if (matchedMagazine) {
          setSelectedMagazine(matchedMagazine);
        } else {
          console.error(`No magazine found for identifier: ${magazineId}`);
          setSelectedMagazine(DUMMY_MAGAZINE);
        }

        // Access the project data
        const projectData = translation[safeLang].projects[projectName];

        // Check if project data exists
        if (!projectData) {
          throw new Error(`Project '${projectName}' not found`);
        }

        // Set the project data to state
        setProjectData(projectData);
      } catch (error) {
        console.error("Error loading translation:", error);
      }
    };

    loadMagazineData();
  }, [lang, projectName, magazineId]);

  useEffect(() => {
    const loadSettings = async () => {
      try {
        const settings = await import(`../../_utility/settings.json`);
        if (settings) {
          setSettings(settings);
        }
      } catch (error) {
        console.error("Error loading settings:", error);
      }
    };

    loadSettings();
  }, []);

  const findMagazine = (
    magazines: { [key: string]: Magazine },
    id: string
  ): Magazine | null => {
    return magazines[id] || null;
  };

  if (!magazineData || !selectedMagazine) {
    return null; // Handle the case when data is not available
  }

  // useEffect(() => {
  //   loadSettings();
  // }, []);

  const typedProjects: Projects = projects;

  const getPseudoElementStyles = (): CustomStyle => {
    const styles = {
      "--main-color": typedProjects[projectName]?.colors?.primary,
      "--second-color": typedProjects[projectName]?.colors?.secondary,
      "--third-color": typedProjects[projectName]?.colors?.tertiary,
    };

    return styles;
  };

  document.documentElement.style.setProperty(
    "--second-color",
    typedProjects[projectName]?.colors?.secondary
  );
  document.documentElement.style.setProperty(
    "--primary-color",
    typedProjects[projectName]?.colors?.primary
  );

  document.documentElement.style.setProperty(
    "--tertiary-color",
    typedProjects[projectName]?.colors?.tertiary
  );

  return (
    <div className={styles.epk} style={getPseudoElementStyles()}>
      <SecondaryHero
        logo={
          "/images/projects/" +
          projectName +
          "/" +
          typedProjects[projectName]?.assets.logo
        }
        src={
          "/images/projects/" +
          projectName +
          "/" +
          typedProjects[projectName]?.assets.cover_image
        }
        posY="center"
      />
      <Shocker
        title={typedProjects[projectName]?.name}
        description={projectData?.project_description}
        video={typedProjects[projectName]?.video}
      />
      <SongSection
        title={languageDefaults.the_song_translation}
        date={PROJECT_DETAILS.RELEASE}
        description={projectData?.song_description}
        about={projectData?.about}
        link="Hello"
        imgURL={
          "/images/projects/" +
          projectName +
          "/" +
          typedProjects[projectName]?.assets.cover
        }
        releaseOn={languageDefaults?.releasing_on_translation}
        releaseDateTitle={languageDefaults?.release_date_translation}
        platforms={
          projectData?.socials
            ?.map((id: string) => MEDIA_CONFIG[id])
            ?.filter(Boolean) || []
        }
        colors={typedProjects[projectName]?.colors}
        albumName={typedProjects[projectName]?.name}
        albumCover={
          "/images/projects/" +
          projectName +
          "/" +
          typedProjects[projectName]?.assets.cover
        }
        audio_src={"/sounds/cds/" + typedProjects[projectName]?.assets.song}
      />
      <BiographySection
        title={languageDefaults?.biography_translation}
        text={projectData?.project_bio}
        image={
          "/images/projects/" +
          projectName +
          "/" +
          typedProjects[projectName]?.assets.profile
        }
      />
      <PressRelease
        pressRelease={languageDefaults?.press_release_translation}
        title={projectData?.press_title}
        sections={projectData?.press_sections}
        imgDescription={projectData?.press_img_description}
        url={"/images/projects/" + projectName + "/"}
        imgURL={
          "/images/projects/" + projectName + "/" + projectData?.press_imgURL
        }
        by={languageDefaults?.by_translation}
        author={selectedMagazine.name}
        pressReleaseUrl={selectedMagazine.website}
        colors={settings?.projectInfo.colors}
        copy={languageDefaults?.copy_translation}
        copied={languageDefaults?.copied_translation}
        spotifyMessage={languageDefaults?.spotify_embed_activation_translation}
      />

      <SocialMediaPost
        contentImages={typedProjects[projectName]?.instagram_images}
        currentProject={projectName}
        description={projectData?.instagram_description}
        likes={364}
        profilePicture={`/images/logo/${selectedMagazine.id}.png`}
        timestamp="10.02 desember"
        name={selectedMagazine.name}
        username={selectedMagazine.username}
        translations={{
          likes: languageDefaults?.likes_translation,
          more: languageDefaults?.more_translation,
          linkInBio: languageDefaults?.link_in_bio_translation,
          title: languageDefaults?.insta_preview_translation,
        }}
      />
      <ImagesSection
        title={languageDefaults?.publicity_photos_translation}
        download={{
          video: languageDefaults?.download_video_translation,
          image: languageDefaults?.download_image_translation,
        }}
        currentProject={projectName}
        images={typedProjects[projectName]?.assets?.press_images}
        names={{
          recommended: languageDefaults?.recommended_translation,
          ratio: languageDefaults?.ratio_translation,
          image: languageDefaults?.photo_translation,
          video: languageDefaults?.video_translation,
          horizontal: languageDefaults?.horizontal_translation,
          vertical: languageDefaults?.vertical_translation,
          filters: languageDefaults?.filters_translation,
        }}
        colors={typedProjects[projectName]?.colors}
      />
      <SocialMedia
        medias={projectData?.socials
          ?.map((id: string) => MEDIA_CONFIG[id])
          ?.filter(Boolean)}
        socialMedias={projectData?.socials_medias
          ?.map((id: string) => SOCIAL_MEDIA_CONFIG[id])
          ?.filter(Boolean)}
        title={languageDefaults?.listen_translation}
        colors={typedProjects[projectName]?.colors}
      />
    </div>
  );
};
