import React, { ReactNode } from "react"; // Import ReactNode type
import styles from "./footer.module.scss";

interface FooterProps {
  children?: ReactNode; // Define the type for children prop
}

export const Footer: React.FC<FooterProps> = ({ children }) => {
  return (
    <div className={styles.footer}>
      {children && <div className={styles.footerSubtitle}>{children}</div>}
      <div className={styles.smallText}>
        <>© 2023 INDIECLIMB</>
      </div>
    </div>
  );
};
