import React from "react";
import styles from "./heading.module.scss";

export const Heading: React.FC<{
  text?: string;
  paddingTop?: number;
  paddingBottom?: number;
  fontSize?: number;
}> = ({ text, paddingTop, paddingBottom, fontSize }) => {
  return (
    <h1
      className={styles.heading}
      style={{
        paddingTop: `${paddingTop}rem`,
        paddingBottom: `${paddingBottom}rem`,
        fontSize: fontSize && fontSize + "rem",
      }}
    >
      {text}
    </h1>
  );
};
