import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
  CSSProperties,
} from "react";
import styles from "./filter.module.scss";

// Interface for Filter props
interface FilterProps {
  choices: string[];
  handleChange: (filter: string) => void;
  filterType?: string;
  translatedValues: string[];
  originalValues: string[];
  colors: string[];
}

interface FilterContextProps {
  openFilter: string | null;
  setOpenFilter: React.Dispatch<React.SetStateAction<string | null>>;
}

const FilterContext = createContext<FilterContextProps | undefined>(undefined);

interface FilterProviderProps {
  children: ReactNode;
}

export const FilterProvider: React.FC<FilterProviderProps> = ({ children }) => {
  const [openFilter, setOpenFilter] = useState<string | null>(null);

  return (
    <FilterContext.Provider value={{ openFilter, setOpenFilter }}>
      {children}
    </FilterContext.Provider>
  );
};

export const Filter: React.FC<FilterProps> = ({
  choices,
  handleChange,
  filterType = choices[0],
  translatedValues,
  originalValues,
  colors,
}) => {
  // Initialize and synchronize selectedFilter
  const initialFilterIndex = translatedValues.indexOf(filterType);
  const initialFilter =
    initialFilterIndex !== -1
      ? originalValues[initialFilterIndex]
      : originalValues[0];
  const [selectedFilter, setSelectedFilter] = useState<string>(initialFilter);

  useEffect(() => {
    const newFilterIndex = translatedValues.indexOf(filterType);
    const newFilter =
      newFilterIndex !== -1
        ? originalValues[newFilterIndex]
        : originalValues[0];
    setSelectedFilter(newFilter);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const context = useContext(FilterContext);

  if (!context) {
    throw new Error("Filter must be used within a FilterProvider");
  }

  const { openFilter, setOpenFilter } = context;
  const expanded = openFilter === filterType;

  const handleExpandClick = () => {
    setOpenFilter((prev) => (prev === filterType ? null : filterType));
  };

  const handleFilterClick = (
    filter: string,
    index: number,
    event: React.MouseEvent
  ) => {
    event.stopPropagation();
    const originalFilter = originalValues[index];
    setSelectedFilter(originalFilter);
    setOpenFilter(null);
    handleChange(originalFilter);
  };

  const getTranslatedValue = (originalValue: string): string => {
    const index = originalValues.indexOf(originalValue);
    return index !== -1 ? translatedValues[index] : originalValue;
  };

  return (
    <div className={styles.filter}>
      <div onClick={handleExpandClick}>
        <span>{getTranslatedValue(selectedFilter)}</span>
        <div
          className={styles.filterArrow}
          style={{
            transform: `${expanded ? "rotate(225deg)" : "rotate(45deg)"}`,
          }}
        ></div>
      </div>
      {expanded && (
        <ul className={styles.filterDropdown}>
          {translatedValues.map((filter, index) => (
            <li
              key={index}
              onClick={(e) => handleFilterClick(filter, index, e)}
            >
              {filter}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
