import React from "react";
import { Header } from "../Header/Header.component";
import { Paragraph } from "../Paragraph/Paragraph.component";
import styles from "./Shocker.module.scss";

interface ShockerProps {
  title: string;
  description: string;
  video: string;
}

export const Shocker: React.FC<ShockerProps> = ({
  title,
  description,
  video,
}) => {
  return (
    <div className={styles.shocker}>
      <Header size="h1" text={title} textAlign="center" />
      <div className={styles.shockerParagraph}>
        <Paragraph text={description} size="large" />
      </div>
      <div className={styles.shockerVideo}>
        <iframe
          width="900px"
          height="600px"
          src={video}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};
