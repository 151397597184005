import React from "react";
import styles from "./home.module.scss";
import { Hero } from "../../components/Hero/hero.component";
import { Footer } from "../../components/Footer/footer.component";

export const Home: React.FC<{ title?: string; subtitle: string }> = ({
  title,
  subtitle,
}) => {
  return (
    <div className={styles.home}>
      <Hero
        hiddenUnderNav
        size={80}
        src="../../images/projects/let-me-go/let-me-go.jpg"
        title={title}
      />

      <Footer>
        <h1>{subtitle}</h1>
      </Footer>
    </div>
  );
};
