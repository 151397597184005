import React, { CSSProperties, useEffect, useRef, useState } from "react";
import styles from "./ImagesSection.module.scss";
import { mediaItems } from "../../productData";
import { Filter, FilterProvider } from "../Filter/filter.component";
import { Masonry } from "@mui/lab";
import { Header } from "../Header/Header.component";
import Icon from "../Icons/Icons.component";

interface NameMappings {
  [key: string]: string;
}

interface ImagesSectionProps {
  title: string;
  download: { video: string; image: string };
  colors: string[];
  names?: NameMappings;
  currentProject: string;
  images?: {
    ratio: string;
    type: string;
    src: string;
    alt: string;
  }[];
}
interface CustomStyle extends CSSProperties {
  "--main-color"?: string;
  "--second-color"?: string;
}

interface MyItemType {
  url: string;
  altText: string;
  ratio: string;
  pxs: string;
  mediaType: string;
  thumbnail?: string;
}

interface ImageWithRatioProps {
  src: string;
  alt: string;
  currentProject: string;
  recommended: string;
  thumbnail?: string;
  download: { image: string; video: string };
  onDownloadClick: () => void; // Assuming this is a function with no arguments and no return value
  colors: {
    primary: string;
    secondary: string;
    tertiary: string;
  };
}

const ImageWithRatio: React.FC<ImageWithRatioProps> = ({
  src,
  alt,
  currentProject,
  onDownloadClick,
  colors,
  recommended,
  download,
  thumbnail,
}) => {
  const [ratio, setRatio] = useState<string[]>([]);

  useEffect(() => {
    if (src.endsWith(".mp4")) {
      const video = document.createElement("video");
      video.src = `/images/projects/${currentProject}/` + src;
      video.onloadedmetadata = () => {
        const calculatedRatio = (video.videoWidth / video.videoHeight).toFixed(
          2
        );
        if (calculatedRatio === "0.56") {
          setRatio([`tiktok`, "instaStory", "instaReels", "snapchat"]);
        } else {
          setRatio([`instagram`, "facebook"]);
        }
      };
    }
    {
      const img = new Image();
      img.src = `/images/projects/${currentProject}/` + src;
      img.onload = () => {
        const calculatedRatio = (img.naturalWidth / img.naturalHeight).toFixed(
          2
        ); // Keeping two decimal places for precision
        if (calculatedRatio === "0.56") {
          setRatio([`tiktok`, "instaStory", "instaReels", "snapchat"]);
        } else {
          setRatio([`instagram`, "facebook"]);
        }
      };
    }
  }, [src, currentProject]);

  return (
    <>
      {src.endsWith(".mp4") ? (
        <>
          <video
            width="100%"
            height="auto"
            controls
            poster={
              thumbnail ? `/images/projects/${currentProject}/${thumbnail}` : ""
            }
            preload="auto" // Preload the video
          >
            <source
              src={`/images/projects/${currentProject}/` + src}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </>
      ) : (
        <img src={`/images/projects/${currentProject}/` + src} alt={alt} />
      )}
      <div className={styles.imagesSectionImageSuggestions}>
        <p className={`${styles.imagesSectionImageRatio}`}>{recommended}:</p>
        {ratio.map((item) => (
          <Icon
            key={item}
            color={colors.secondary}
            name={item}
            bg={false}
            size={20}
            toolTip={item}
          />
        ))}
      </div>
      <div
        className={styles.imagesSectionImageSubtitle}
        onClick={onDownloadClick}
      >
        {src.endsWith(".mp4") ? download.video : download.image}
      </div>
    </>
  );
};

export const ImagesSection: React.FC<ImagesSectionProps> = ({
  title,
  download,
  names,
  images,
  colors,
  currentProject,
}) => {
  const [columns, setColumns] = useState(3);
  const [selectedRatio, setSelectedRatio] = useState<string | null>(null);
  const [selectedType, setSelectedType] = useState<string | null>(null);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const sentinel = useRef(null);

  const [currentIndex, setCurrentIndex] = useState<number | null>(null);

  const { filters, ratio } = names || {
    filters: "Filters",
    ratio: "Ratio",
    image: "Image",
    horizontal: "Horizontal",
    vertical: "Vertical",
  };

  const getPseudoElementStyles = (): CustomStyle => {
    if (!colors || colors.length < 2) {
      return {};
    }

    const styles = {
      "--main-color": colors[0],
      "--second-color": colors[1],
      "--third-color": colors[2],
    };

    console.log(styles); // Check the output in the browser console
    return styles;
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [correspondingValues, setCorrespondingValues] = useState<string[]>([]);

  useEffect(() => {
    if (names) {
      // only run this logic if names is defined
      const values = getCorrespondingValues(uniqueRatios, names);
      setCorrespondingValues(values); // save the results to state
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [names]);

  useEffect(() => {
    const updateColumns = () => {
      if (window.innerWidth <= 1800 && window.innerWidth > 1200) {
        setColumns(2);
      }

      if (window.innerWidth <= 1200 && window.innerWidth > 768) {
        // Assuming tablet width is greater than 768px
        setColumns(2);
      }

      if (window.innerWidth <= 768) {
        // Assuming mobile width is 768px or less
        setColumns(1);
      }
    };

    updateColumns();

    window.addEventListener("resize", updateColumns);

    return () => window.removeEventListener("resize", updateColumns);
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape" && selectedImage) {
        event.preventDefault();
        handleCloseImage();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectedImage]);

  const filtersRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (filtersRef.current) {
      if (window.scrollY >= 7.5 * 16) {
        // Convert rem to px assuming 1rem = 16px
        filtersRef.current.classList.add(styles.sticky);
      } else {
        filtersRef.current.classList.remove(styles.sticky);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const ratios = images?.map((item) => item.ratio);
  const uniqueRatios = Array.from(new Set(ratios));

  const types = images?.map((item) => item.type);
  const uniqueTypes = Array.from(new Set(types));

  // console.log(Object.values(names).map((value) => value));
  function getCorrespondingValues(
    array: string[],
    obj: NameMappings | null | undefined
  ): string[] {
    if (!obj) {
      // If obj is null or undefined, return the original array
      return array;
    }
    return array?.map((item) => obj[item?.toLowerCase()] || item);
  }

  const filteredItems: any = images?.filter(
    (item) =>
      (selectedType ? item.type === selectedType : true) &&
      (selectedRatio ? item.ratio === selectedRatio : true)
  );

  const createHandleImageDownload =
    (imageSrc: string, fileName: string) => () => {
      fetch(imageSrc)
        .then((image) => image.blob())
        .then((imageBlob) => URL.createObjectURL(imageBlob))
        .then((imageURL) => {
          const link = document.createElement("a");
          link.href = imageURL;
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
    };

  // const handleImageClick = (imageUrl: string) => {
  //   setSelectedImage(imageUrl);
  // };

  const handleCloseImage = () => {
    setSelectedImage(null);
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (selectedImage) {
        if (event.key === "Escape") {
          event.preventDefault();
          handleCloseImage();
        } else if (
          event.key === "ArrowLeft" &&
          currentIndex !== null &&
          currentIndex > 0
        ) {
          event.preventDefault();
          setCurrentIndex(currentIndex - 1);
          setSelectedImage(filteredItems[currentIndex - 1].src);
        } else if (
          event.key === "ArrowRight" &&
          currentIndex !== null &&
          currentIndex < filteredItems.length - 1
        ) {
          event.preventDefault();
          setCurrentIndex(currentIndex + 1);
          setSelectedImage(filteredItems[currentIndex + 1].src);
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectedImage, currentIndex, filteredItems]);

  const handleImageClick = (imageUrl: string, index: number) => {
    setSelectedImage(imageUrl);
    setCurrentIndex(index);
  };

  return (
    <div className={styles.imagesSection}>
      <Header size="h2" text={title} textAlign="center" marginBottom="2rem" />
      <div className={styles.sentinel} ref={sentinel}></div>
      <div className={styles.imagesSectionFilters} ref={filtersRef}>
        <h3>{filters}</h3>
        <FilterProvider>
          <Filter
            choices={uniqueTypes}
            handleChange={setSelectedType}
            filterType={filters}
            translatedValues={getCorrespondingValues(uniqueTypes, names)}
            originalValues={uniqueTypes}
            colors={colors}
          />

          <Filter
            choices={uniqueRatios}
            handleChange={setSelectedRatio}
            filterType={ratio}
            translatedValues={getCorrespondingValues(uniqueRatios, names)}
            originalValues={uniqueRatios}
            colors={colors}
          />
        </FilterProvider>
      </div>
      <Masonry
        columns={columns}
        spacing={0.6}
        defaultHeight={100}
        defaultColumns={1}
        defaultSpacing={1}
      >
        {filteredItems?.map((item: any, index: number) => (
          <div
            key={item.src}
            className={styles.imagesSectionImage}
            onClick={
              !item.src.endsWith(".mp4")
                ? () => handleImageClick(item.src, index)
                : undefined
            }
          >
            <ImageWithRatio
              src={item.src}
              alt={item.altText}
              currentProject={currentProject}
              download={download}
              thumbnail={item.thumbnail}
              // @ts-ignore
              colors={colors}
              onDownloadClick={createHandleImageDownload(
                `/images/projects/${currentProject}/` + item.src,
                `LET ME GO-${index}.${
                  !item.src.endsWith("mp4") ? "jpg" : "mp4"
                }`
              )}
              recommended={(names && names.recommended) || ""}
            />
          </div>
        ))}
      </Masonry>

      {selectedImage && (
        <div className={styles.fullScreenImageContainer}>
          <div className={styles.fullScreenImage}>
            {selectedImage.endsWith(".mp4") ? (
              <video
                width="100%"
                height="auto"
                controls
                autoPlay
                preload="auto"
              >
                <source
                  src={`/images/projects/${currentProject}/` + selectedImage}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img
                src={`/images/projects/${currentProject}/` + selectedImage}
                alt="Full screen"
                onClick={handleCloseImage}
              />
            )}

            <div
              className={styles.closeButton}
              onClick={handleCloseImage}
              style={{
                color: colors ? colors[0] : "",
              }}
            >
              X
            </div>
            <div
              className={styles.imagesSectionImageSubtitle}
              onClick={createHandleImageDownload(
                `/images/projects/${currentProject}/` + selectedImage,
                `media-fullscreen.${
                  selectedImage.endsWith(".mp4") ? "mp4" : "jpg"
                }`
              )}
            >
              {download.image}
            </div>
            <div>
              <div
                className={styles.closeButton}
                onClick={handleCloseImage}
                style={getPseudoElementStyles()}
              ></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
