export const DUMMY_MAGAZINE = {
  name: "Unavailable Magazine",
  link: "https://www.domain.com",
  author: "Dummy",
  //... more fields with dummy content
};

export const DUMMY_TRANTSLATION = {
  spotify_embed_activation_translation: "Activate Spotify Embed",
  copied_translation: "Copied",
  recommended_translation: "Recommended",
  biography_translation: "السيرة الذاتية",
  download_image_translation: "تحميل الصورة",
  download_video_translation: "تحميل الفيديو",
  download_song_translation: "تحميل الأغنية",
  download_pdf_translation: "تحميل PDF",
  copy_translation: "نسخ",
  publicity_photos_translation: "صور دعائية",
  press_release_translation: "بيان صحفي",
  by_translation: "بواسطة",
  listen_translation: "استماع",
  release_date_translation: "تاريخ الإصدار",
  show_more_translation: "عرض المزيد",
  link_in_bio_translation: "الرابط في السيرة الذاتية",
  more_translation: "المزيد",
  likes_translation: "الإعجابات",
  type_translation: "نوع",
  filters_translation: "فلاتر",
  the_song_translation: "الأغنية",
  ratio_translation: "نسبة",
  releasing_on_translation: "إصدار في",
  vertical_translation: "عمودي",
  horizontal_translation: "أفقي",
  visit_translation: "زيارة",
  music_translation: "موسيقى",
  video_translation: "فيديو",
  photo_translation: "صورة",
  all_translation: "الكل",
  share_translation: "مشاركة",
  play_translation: "تشغيل",
  page_not_found_translation: "الصفحة غير موجودة",
  go_back_translation: "العودة",
  home_translation: "الرئيسية",
  insta_preview_translation: "معاينة إنستا",
  mail_translation: {
    subject:
      'إصدار: الأغنية الجديدة والفيديو لـ {artist_name} "{song_title}" مناسبة تمامًا لـ {magazine_blog_name}',
    greeting: "عزيزي/عزيزتي {recipient_name},",
    introduction:
      'اسمي {artist_name}، منتج ومخرج نرويجي متحمس. لقد تابعت {magazine_blog_name} لبعض الوقت واكتشفت العديد من الفنانين الملهمين من خلال منصتكم. بحماس كبير، أود مشاركة أحدث أغنية لي والفيديو الموسيقي المرافق لها، "{song_title}", المقرر إصداره في {release_date}. هذه هي الأغنية الأولى والفيديو الذي أطلقه من ألبومي الأول المرتقب. حققت أغنيتي السابقة أكثر من 36,000 استماع على Spotify، وأنا متفائل بأن \'Let Me Go\' ستجد صدى لدى قراء {magazine_blog_name}. سأشعر بالشرف لمناقشة إمكانية الحصول على مراجعة أو مقالة. في الفيديو، تعاونت مع DJ Dulcedoll، دي جي شابة وواعدة تعزف إلى جانب SAA_G المعروف في النرويج، بالإضافة إلى الممثلة Ida Malvik، التي ظهرت في الفيلم القصير البارز في مهرجان كان "Tits" لـ Eivind Landsvik. هذا التعاون قد رفع حقًا من مستوى المشروع، وأعتقد أنه سيجذب جمهور {magazine_blog_name}.',
    epk_details: {
      intro:
        "لقد أعددت حقيبة صحفية إلكترونية حصرية (EPK) تحتوي على كل ما تحتاجونه لفهم شامل للمشروع:",
      music_video_link: "• رابط خاص بالفيديو الموسيقي",
      song_info: '• معلومات حول "{song_title}" والفيديو الموسيقي المرافق لها',
      press_release: "• البيان الصحفي الرسمي",
      photos: "• صور عالية الدقة",
      social_media_links: "• روابط لملفاتي الشخصية على وسائل التواصل الاجتماعي",
    },
    epk_link: "EPK: {epk_link}",
    collaboration_offer:
      "أنا أيضًا منفتح على تقديم معلومات إضافية، والمشاركة في المقابلات، أو مشاركة المحتوى الحصري مع قراء {magazine_blog_name}. أنا مهتم جدًا باستكشاف إمكانيات التعاون حول مقالة أو مراجعة.",
    appreciation:
      "أتطلع إلى سماع أخباركم وأنا متاح لمزيد من النقاش عبر البريد الإلكتروني أو الهاتف.",
    closing: "مع أطيب التحيات،",
    signature: "مارتن فيلد",
  },
};
