import React from "react";
import styles from "./Header.module.scss";

interface HeaderProps {
  text: string;
  size: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  color?: "black" | "white";
  textAlign?: "left" | "right" | "center" | "justify";
  isCenterPhone?: "no" | "yes";
  marginTop?: string;
  marginBottom?: string;
}

export const Header: React.FC<HeaderProps> = ({
  size,
  text,
  color = "white",
  textAlign = "left",
  isCenterPhone = "no",
  marginTop,
  marginBottom,
}) => {
  const centerPhoneClass = isCenterPhone === "yes" ? styles.isCenterPhone : "";

  const headers = {
    h1: (
      <h1
        className={`${styles.headerFirst} ${styles[color]} ${centerPhoneClass} ${styles[textAlign]}`}
        style={{ marginTop, marginBottom }}
      >
        {text}
      </h1>
    ),
    h2: (
      <h2
        className={`${styles.headerSecond} ${styles[color]} ${centerPhoneClass} ${styles[textAlign]}`}
        style={{ marginTop, marginBottom }}
      >
        {text}
      </h2>
    ),
    h3: (
      <h3
        className={`${styles.headerThird} ${styles[color]} ${centerPhoneClass} ${styles[textAlign]}`}
        style={{ marginTop, marginBottom }}
      >
        {text}
      </h3>
    ),
    h4: (
      <h4
        className={`${styles.headerFourth} ${styles[color]} ${centerPhoneClass} ${styles[textAlign]}`}
        style={{ marginTop, marginBottom }}
      >
        {text}
      </h4>
    ),
    h5: (
      <h5
        className={`${styles.headerFifth} ${styles[color]} ${centerPhoneClass} ${styles[textAlign]}`}
        style={{ marginTop, marginBottom }}
      >
        {text}
      </h5>
    ),
    h6: (
      <h6
        className={`${styles.headerSixth} ${centerPhoneClass} ${styles[color]} ${styles[textAlign]}`}
        style={{ marginTop, marginBottom }}
      >
        {text}
      </h6>
    ),
  };

  return (
    headers[size] || (
      <p
        className={`${styles[color]} ${styles[textAlign]}`}
        style={{ marginTop, marginBottom }}
      >
        {text}
      </p>
    )
  );
};
