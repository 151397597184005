import React from "react";
import styles from "./Paragraph.module.scss";

interface ParagraphProps {
  size: "xsmall" | "small" | "medium" | "large";
  color?: "black" | "white";
  italic?: boolean;
  bold?: boolean;
  isCenterPhone?: "no" | "yes";
  text?: string; // Make text optional
  children?: React.ReactNode; // Add children prop
  marginTop?: string;
  marginBottom?: string;
}

export const Paragraph: React.FC<ParagraphProps> = ({
  size,
  color = "white",
  italic = false,
  bold = false,
  isCenterPhone = "no",
  text,
  children, // Destructure children prop
  marginTop,
  marginBottom,
}) => {
  const content = text || children; // Use text or children, whichever is provided

  const centerPhoneClass = isCenterPhone === "yes" ? styles.isCenterPhone : "";

  const paragraphs = {
    xsmall: (
      <p
        className={`${styles.paragraphZero} ${centerPhoneClass} ${
          styles[color]
        } ${italic ? styles.italic : ""} ${bold ? styles.bold : ""}`}
        style={{ marginTop, marginBottom }}
      >
        {content}
      </p>
    ),
    small: (
      <p
        className={`${styles.paragraphFirst} ${centerPhoneClass} ${
          styles[color]
        } ${italic ? styles.italic : ""} ${bold ? styles.bold : ""}`}
        style={{ marginTop, marginBottom }}
      >
        {content}
      </p>
    ),
    medium: (
      <p
        className={`${styles.paragraphSecond} ${centerPhoneClass} ${
          styles[color]
        } ${italic ? styles.italic : ""} ${bold ? styles.bold : ""}`}
        style={{ marginTop, marginBottom }}
      >
        {content}
      </p>
    ),
    large: (
      <p
        className={`${styles.paragraphThird} ${centerPhoneClass} ${
          styles[color]
        } ${italic ? styles.italic : ""} ${bold ? styles.bold : ""}`}
        style={{ marginTop, marginBottom }}
      >
        {content}
      </p>
    ),
  };

  return (
    paragraphs[size] || (
      <p
        className={`${styles[color]} ${italic ? styles.italic : ""} ${
          bold ? styles.bold : ""
        }`}
        style={{ marginTop, marginBottom }}
      >
        {content}
      </p>
    )
  );
};
