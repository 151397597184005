import React, { useEffect } from "react";
import styles from "./SecondaryHero.module.scss";

export const SecondaryHero: React.FC<{
  src: string;
  size?: number;
  title?: string;
  hiddenUnderNav?: boolean;
  posY?: "top" | "bottom" | "center";
  logo: string;
}> = ({ src, title, hiddenUnderNav, posY = "top", logo }) => {
  return (
    <div
      className={styles.hero}
      style={{
        backgroundImage: `url("${src}")`,
        backgroundPositionY: posY,
      }}
    >
      <div className={styles.heroProfileImage}>
        <img src={logo} alt="" />
      </div>
    </div>
  );
};
