import React from "react";
import styles from "./navbar.module.scss";
import { Link } from "react-router-dom";

export const Navbar: React.FC<{}> = () => {
  const [expanded, setExpanded] = React.useState(false);

  const expand = () => {
    setExpanded(!expanded);
  };

  return (
    <nav className={styles.navbar}>
      <div className={styles.logo}>
        <Link to="/">Indieclimb</Link>
      </div>

      <div>
        <div
          className={`${styles.burger} ${expanded ? styles.expanded : ""}`}
          onClick={expand}
        >
          <div className={styles.burgerMenuLine1}></div>
          <div className={styles.burgerMenuLine2}></div>
          <div className={styles.burgerMenuLine3}></div>
        </div>

        <ul className={`${styles.links} ${expanded ? styles.expanded : ""}`}>
          <li>
            <Link
              onClick={expand}
              to="https://linktr.ee/indieclimb"
              target="_blank"
            >
              Music
            </Link>
          </li>
          <li>
            <Link onClick={expand} to="/videos">
              Videos
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};
