import React, { useState, useEffect, useRef } from "react";
import styles from "./RecordSpinner.module.scss";
import ControlPad from "../ControlPad/ControlPad.component";

interface RecordSpinnerProps {
  img: string;
  audioSrc: string;
  cover: string;
  colors?: {
    primary: string;
    secondary: string;
    tertiary: string;
  };
  albumName: string;
}

export const RecordSpinner: React.FC<RecordSpinnerProps> = ({
  img,
  audioSrc,
  cover,
  colors,
  albumName,
}) => {
  const [isSpinning, setIsSpinning] = useState(false);
  const [degree, setDegree] = useState(0); // State for rotation degree
  const [maxDuration, setMaxDuration] = useState(0);
  const [sliderValue, setSliderValue] = useState(0);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const intervalRef = useRef<number | null>(null); // Ref for the interval id

  useEffect(() => {
    // Set up metadata loaded event listener
    if (audioRef.current) {
      const currentAudioRef = audioRef.current;
      currentAudioRef.addEventListener("loadedmetadata", () => {
        setMaxDuration(currentAudioRef.duration || 0);
      });
    }

    return () => {
      // Clean up event listener
      if (audioRef.current) {
        const currentAudioRef = audioRef.current;
        currentAudioRef.removeEventListener("loadedmetadata", () => {});
      }
    };
  }, []);

  useEffect(() => {
    // Set up ended event listener
    if (audioRef.current) {
      audioRef.current.addEventListener("ended", () => {
        setIsSpinning(false);
        setSliderValue(0);
      });
    }

    return () => {
      // Clean up event listener
      if (audioRef.current) {
        audioRef.current.removeEventListener("ended", () => {});
      }
    };
  }, []);

  useEffect(() => {
    // Handle time updates for slider
    const handleTimeUpdate = () => {
      if (audioRef.current) {
        const currentTime = audioRef.current.currentTime;
        setSliderValue(currentTime);
      }
    };

    if (audioRef.current) {
      audioRef.current.addEventListener("timeupdate", handleTimeUpdate);
    }

    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener("timeupdate", handleTimeUpdate);
      }
    };
  }, []);

  useEffect(() => {
    // Set up the spinning functionality
    if (isSpinning) {
      intervalRef.current = window.setInterval(() => {
        setDegree((prevDegree) => (prevDegree + 0.5) % 360);
      }, 1); // Rotate the image a little every 50ms
    } else {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    }

    return () => {
      // Clear the spinning interval when component is unmounted or isSpinning changes
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [isSpinning]);

  const toggleSpinning = () => {
    // Function to handle the spinning toggle
    setIsSpinning((prevIsSpinning) => !prevIsSpinning);
    if (audioRef.current) {
      if (isSpinning) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
    }
  };

  const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault(); // Prevent default behavior that might interfere
    const time = parseFloat(event.target.value);
    setSliderValue(time);
    // Update audio currentTime only if it's different to avoid unnecessary updates
    if (audioRef.current && audioRef.current.currentTime !== time) {
      audioRef.current.currentTime = time;
    }
  };

  // Updated inline style for the image to include dynamic rotation based on the degree state
  const rotationStyle: React.CSSProperties = {
    transform: `rotate(${degree}deg)`,
    transition: isSpinning ? "none" : "transform 1s ease-out", // No transition when spinning, smooth stop
    transformOrigin: "center",
  };

  return (
    <div className={styles.recordSpinner}>
      <div style={rotationStyle} className={styles.temp}>
        <div className={styles.recordSpinnerSpindle}></div>
        <img
          src={img}
          alt="Record Spinner"
          className={styles.recordSpinnerImg}
        />
        <div className={styles.recordSpinnerHolo}></div>
        <div className={styles.recordSpinnerCoverContainer}>
          <img src={cover} alt="cover" className={styles.recordSpinnerCover} />
          <h1>{albumName}</h1>
        </div>
      </div>

      <ControlPad
        handleClick={toggleSpinning}
        isSpinning={isSpinning}
        colors={colors}
      />

      {/* <input
        type="range"
        min="0"
        max={maxDuration}
        value={sliderValue}
        onChange={handleSliderChange}
      /> */}
      <audio ref={audioRef} src={audioSrc} preload="auto"></audio>
    </div>
  );
};

export default RecordSpinner;
