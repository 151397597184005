import React, { useState } from "react";
import styles from "./music.module.scss";

interface MyObject {
  name: string;
  logo: string;
  link?: string; // Optional property
}

export const Music: React.FC<{
  img: string;
  title: string;
  name: string;
  subtitle: string;
  platforms: Array<MyObject>;
}> = ({ img, name, platforms, subtitle, title }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [latestRelease, setLatestRelease] = useState<any>(null);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       // Replace 'YOUR_ACCESS_TOKEN' with the actual access token you obtained from Spotify
  //       const accessToken =
  //         "BQBW8gdkRyYkBsXLkh6TYN6CeCUACN36R--GCt6q9nxab8WwzehytTw4VGZomybwsG5xFlgYkiIf71Otm2u6ls9eO538mdtFpvuCfql5GmRgoEZzSqw";
  //       const headers = {
  //         Authorization: `Bearer ${accessToken}`,
  //       };

  //       // Get the artist's top tracks
  //       const response = await axios.get(
  //         `https://api.spotify.com/v1/artists/5wk5mLirrHPNbkvor0f5S2/albums?include_groups=album,single`,
  //         { headers }
  //       );

  //       // Assuming the response contains the latest release as the first item
  //       if (
  //         response.data &&
  //         response.data.items &&
  //         response.data.items.length > 0
  //       ) {
  //         setLatestRelease(response.data.items[0]);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching artist latest release:", error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  return (
    <section className={styles.music}>
      <div className={styles.cover}>
        <img src={img} className={styles.vhs} alt="VHS Decorative" />
        {img ? (
          <img src={img} alt={`Album cover of ${name}`} />
        ) : (
          <div>No album cover available</div>
        )}
      </div>
      <header className={styles.header}>
        <h2 className={styles.artistNames}>
          {latestRelease?.artists
            .map((artist: any) => artist.name)
            .sort()
            .join(", ")}
        </h2>
        <h1 className={styles.albumTitle}>{latestRelease?.name || title}</h1>
        <div className={styles.subtitle}>
          <p>
            New
            {latestRelease?.total_tracks > 1 &&
            latestRelease?.album_type === "single"
              ? " EP"
              : ` EP `}
            out now:
          </p>
        </div>
      </header>
      <nav>
        <a href="#s" className={styles.scrollIndicator}>
          <div className={styles.arrow}>
            <img
              src="https://icons.veryicon.com/png/o/miscellaneous/arrows/double-arrow-down-2.png"
              alt="Scroll Down Arrow"
            />
          </div>
          <div className={styles.pulse}></div>
        </a>
      </nav>
      <aside className={styles.platforms}>
        {platforms.map((platform: MyObject) => (
          <div className={styles.platform}>
            <img src={platform.logo} alt={`${platform.name} Logo`} />
            <a href={platform.link}>
              <span>{platform.name}</span>
            </a>
          </div>
        ))}
      </aside>
    </section>
  );
};
