type ArticleImages = Record<string, string>;

interface ProjectAssets {
  ARTICLE_IMAGES: ArticleImages;
  PROFILE_IMG: string;
}

export const ASSETS: ProjectAssets = {
  ARTICLE_IMAGES: {},
  PROFILE_IMG: "",
};

export const PROJECT_DETAILS = {
  NAME: "Let me go",
  RELEASE: "12.03.2024",
  URL: "https://example.com",
  ASSETS,
};
