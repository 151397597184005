import React from "react";
import styles from "./hero.module.scss";
import { Heading } from "../Heading/heading.component";

export const Hero: React.FC<{
  src: string;
  size?: number;
  title?: string;
  hiddenUnderNav?: boolean;
}> = ({ src, title, hiddenUnderNav }) => {
  return (
    <div
      className={styles.hero}
      style={{
        backgroundImage: `url("${src}")`,
      }}
    >
      <Heading text={title} />
      <a
        href="https://tr.ee/v4CKFeaECI"
        className={styles.saveButton}
        target="_blank"
        rel="noreferrer"
      >
        <p>Listen now</p>
      </a>
    </div>
  );
};
