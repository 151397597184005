import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Music } from "./pages/Music/music.page";
import { Home } from "./pages/Home/home.page";
import Videos from "./pages/Videos/videos.page";
import { Navbar } from "./components/Navbar/navbar.component";
import "./scss/_base.scss";
import { EPK } from "./pages/EPK/epk.page";
import { useState, useEffect } from "react";

import { Helmet } from "react-helmet";

import currentProjectData from "../src/_config/currentProject.json";
import projectsData from "../src/_config/projects.json";

// Define a type for the structure of assets images
interface AssetImage {
  src: string;
  alt: string;
  ratio: string;
}

// Define a type for the structure of assets
interface Asset {
  images: AssetImage[];
  song: string;
  cover: string;
  logo: string;
  profile: string;
  cover_image: string;
  press_images: AssetImage[];
}

// Define a type for the structure of a project
interface ProjectData {
  name: string;
  emoji: string;
  assets: Asset;
  artist: string;
  featuring: string;
  video: string;
  colors: {
    primary: string;
    secondary: string;
    tertiary: string;
  };
  song: string; // This property is duplicated in the JSON, you may want to remove one
  press_imgURL?: string; // Make these properties optional
  instagram_images?: string[]; // Make these properties optional
}

// Define a type for the entire projectsData object
type ProjectsData = Record<string, ProjectData>;

// function PasswordProtection({
//   correctPassword,
//   onPasswordSubmit,
// }: {
//   correctPassword: string;
//   onPasswordSubmit: () => void;
// }) {
//   const [password, setPassword] = useState("");
//   const [isAuthorized, setIsAuthorized] = useState(false);
//   const [isPasswordError, setIsPasswordError] = useState("");

//   useEffect(() => {
//     const savedPassword = localStorage.getItem("password");
//     if (savedPassword === correctPassword) {
//       setIsAuthorized(true);
//       onPasswordSubmit();
//     }
//   }, []);

//   const handlePasswordSubmit = () => {
//     if (password === correctPassword) {
//       setIsAuthorized(true);
//       localStorage.setItem("password", password); // Save password to local storage
//       onPasswordSubmit();
//     } else {
//       setIsPasswordError("Incorrect password. Please try again.");
//       setPassword("");
//     }
//   };

//   return isAuthorized ? (
//     <MainContent />
//   ) : (
//     <div className="password-protection">
//       <h2>Password required</h2>
//       <p>Please enter the password to access the app:</p>
//       <input
//         autoFocus
//         type="password"
//         placeholder="Enter Password"
//         value={password}
//         onChange={(e) => setPassword(e.target.value)}
//       />
//       <button onClick={handlePasswordSubmit}>Enter</button>
//       {isPasswordError && <p className="error">{isPasswordError}</p>}
//     </div>
//   );
// }

function MainContent() {
  const currentProjectName = currentProjectData.currentProject;
  const projectInfo = (projectsData as ProjectsData)[currentProjectName];

  const colors = projectInfo.colors;

  document.documentElement.style.setProperty(
    "--second-color",
    colors.secondary
  );
  document.documentElement.style.setProperty("--primary-color", colors.primary);
  document.documentElement.style.setProperty(
    "--tertiary-color",
    colors.tertiary
  );

  return (
    <main>
      <Navbar />
      <Routes>
        <Route
          path="/"
          element={<Home title={projectInfo.name} subtitle="OUT NOW" />}
        />
        <Route path="/:lang/:projectName/:magazineId/epk" element={<EPK />} />

        <Route
          path="/music"
          element={
            <Music
              img="./images/I_HOPE_YOU_SEE_THIS.jpeg"
              name="Indieclimb"
              platforms={[
                {
                  name: "Watch video",
                  link: "Hello",
                  logo: "https://assets.ams-prd.blv.cloud/images/stores/logo-youtube-label.png",
                },
                {
                  name: "Listen",
                  link: "Hello",
                  logo: "https://assets.ams-prd.blv.cloud/images/stores/logo-appleMusic-label.png",
                },
                {
                  name: "Listen",
                  link: "Hello",
                  logo: "https://assets.ams-prd.blv.cloud/images/stores/logo-spotify-label.png",
                },
                {
                  name: "Listen",
                  link: "Hello",
                  logo: "https://assets.ams-prd.blv.cloud/images/stores/logo-tidal-label.png",
                },
                {
                  name: "Listen",
                  link: "Hello",
                  logo: "https://assets.ams-prd.blv.cloud/images/stores/logo-youtubeMusic-label.png",
                },
              ]}
              subtitle="Indieclimb:"
              title="TITLE"
            />
          }
        />
        <Route path="/videos" element={<Videos />} />
      </Routes>
    </main>
  );
}

function App() {
  return (
    <div>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Router>
        <MainContent />
      </Router>
    </div>
  );
}

export default App;
