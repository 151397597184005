import React from "react";
import styles from "./SocialMedia.module.scss";
import { Header } from "../Header/Header.component";
import Icon from "../Icons/Icons.component";

// Define the shape of a media item
export interface MediaItem {
  url: string;
  image: string;
  name?: string;
}

// Define the prop types for the component
interface SocialMediaProps {
  medias?: MediaItem[];
  socialMedias?: MediaItem[];
  title: string;
  colors?: {
    primary: string;
    secondary: string;
    tertiary: string;
  };
}

// Component
export const SocialMedia: React.FC<SocialMediaProps> = ({
  medias,
  socialMedias,
  title,
  colors,
}) => {
  // If medias is not defined or not an array, render nothing
  if (!medias || !Array.isArray(medias)) return null;

  return (
    <div className={styles.socialMedia}>
      <Header text={title} size="h6" textAlign="left" isCenterPhone="yes" />
      <div className={styles.iconsContainer}>
        <div className={styles.socialMediaMusicServices}>
          {medias?.map((item, index) => (
            <a
              key={index}
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={styles.socialMediaBadge}>
                <Icon
                  name={item?.name?.toLocaleLowerCase()}
                  color={colors?.secondary}
                  bg={false}
                />
              </div>
            </a>
          ))}
        </div>
        <div className={styles.socialMediaPlatforms}>
          {socialMedias?.map((item, index) => (
            <a
              key={index}
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={styles.socialMediaBadge}>
                <Icon
                  name={item?.name?.toLocaleLowerCase()}
                  color={colors?.secondary}
                  bg={false}
                  isSocialMedia={true}
                />
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};
