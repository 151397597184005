import React, { CSSProperties, useState } from "react";
import styles from "./PressRelease.module.scss";
import { Header } from "../Header/Header.component";
import { Paragraph } from "../Paragraph/Paragraph.component";
import { PROJECT_DETAILS } from "../../_config/_project_details";
import SocialMediaPost from "../SocialMediaPost/SocialMediaPost.component";
import Icon from "../Icons/Icons.component";

type PressImage = {
  url: string;
  small: boolean;
  alt: string;
};

type SpotifyEmbed = {
  url: string;
};

type IframeEmbed = {
  url: string;
  width: string;
  height: string;
};

type Section = {
  type: "text" | "image" | "spotify" | "embed";
  italic?: boolean;
  bold?: boolean;
  content: string | PressImage | SpotifyEmbed | IframeEmbed;
};
type Sections = Record<string, Section>;

const CopyToClipboardButton: React.FC<{
  textToCopy: string;
  color: string;
  copied_translation: string;
  copy: string;
}> = ({ textToCopy, color, copied_translation, copy }) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  return (
    // <button onClick={() => copyToClipboard(textToCopy)}>
    //   Copy to Clipboard
    // </button>
    <div className={styles.spotifyEmbedCopy}>
      <p className={styles.spotifyEmbedLink}>
        https://open.spotify.com/embed/album/0gpKaF4NMJv1u9L7GQ1eFy
      </p>
      <div
        className={styles.spotifyEmbedCopyButton}
        onClick={() => copyToClipboard(textToCopy)}
      >
        <p
          className={`${styles.copiedText} ${!copied && styles.opacityFadeout}`}
        >
          {copied_translation}
        </p>
        <Icon name="copyToClipboard" color={"white"} bg={false} size={15} />
        {/* <p
          style={{
            fontSize: ".7rem",
            textTransform: "uppercase",
            color: "#fff",
          }}
        >
          {copy}
        </p> */}
      </div>
    </div>
  );
};

export const PressRelease: React.FC<{
  title: string;
  sections: Sections;
  imgURL: string;
  imgDescription: string;
  by: string;
  author: string;
  pressRelease: string;
  pressReleaseUrl: string;
  colors: string[];
  url: string;
  copy: string;
  copied: string;
  spotifyMessage: string;
}> = ({
  imgDescription,
  imgURL,
  sections = {},
  title,
  by,
  author,
  pressRelease,
  pressReleaseUrl,
  colors,
  url,
  spotifyMessage,
  copy,
  copied,
}) => {
  interface CustomStyle extends CSSProperties {
    "--main-color"?: string;
    "--second-color"?: string;
  }
  const getPseudoElementStyles = (): CustomStyle => {
    if (!colors || colors.length < 2) {
      return {};
    }

    const styles = {
      "--main-color": colors[0],
      "--second-color": colors[1],
      "--third-color": colors[2],
    };

    return styles;
  };

  return (
    <div className={styles.wrapper}>
      <Header
        text={pressRelease}
        size="h2"
        textAlign="center"
        marginBottom="2rem"
      />
      <div className={styles.pressRelease}>
        <div className={styles.pressReleaseBar}>
          <div className={styles.pressReleaseBarSearch}>
            <img src="/images/icons/lock.png" alt="" />
            {pressReleaseUrl}
          </div>
        </div>
        <div className={styles.pressReleaseBackEffect}></div>
        <div className={styles.pressReleaseImg}>
          <img src={imgURL} alt="" />
          <p>{imgDescription}</p>
        </div>
        <Header
          text={title}
          size="h3"
          textAlign="left"
          color="black"
          isCenterPhone="no"
          marginBottom="1rem"
          marginTop="2rem"
        />
        <span>{by}</span>{" "}
        <span className={styles.pressReleaseAuthor}>{author}</span>
        <p className={styles.pressReleaseDate}>
          {" "}
          {PROJECT_DETAILS.RELEASE}{" "}
          {new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          })}
        </p>
        {/* {Object.values(sections).map((section, i) =>
          section.type === "image" ? (
            <div className={styles.pressReleaseImg}>
              <img
                style={{
                  width: (section.content as PressImage).small ? "50%" : "100%",
                }}
                src={
                  typeof section.content === "string"
                    ? section.content
                    : section.content.url
                }
                alt=""
              />
              <p
                style={{
                  width: (section.content as PressImage).small ? "50%" : "100%",
                }}
              >
                {(section.content as PressImage).alt}
              </p>
            </div>
          ) : (
            <Paragraph
              key={i}
              text={typeof section.content === "string" ? section.content : ""}
              size="medium"
              isCenterPhone="no"
              color="black"
              marginBottom="1rem"
            />
          )
        )} */}
        {Object.values(sections)?.map((section, i) => {
          switch (section.type) {
            case "image":
              return (
                <div
                  className={`${styles.pressReleaseImg} ${
                    (section.content as PressImage).small
                      ? styles.pressReleaseImgSmall
                      : ""
                  }`}
                  key={i}
                >
                  <img src={url + (section.content as PressImage).url} alt="" />
                  {(section.content as PressImage).alt && (
                    <p>{(section.content as PressImage).alt}</p>
                  )}
                </div>
              );
            case "spotify":
              return (
                <div key={i} className={styles.pressReleaseSpotify}>
                  <iframe
                    title="spotify"
                    src={`https://open.spotify.com/embed/track/${
                      (section.content as SpotifyEmbed).url
                    }`}
                    width="100%"
                    height="152"
                    frameBorder="0"
                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                    loading="lazy"
                  ></iframe>
                </div>
              );
            case "embed":
              return (
                <div className={styles.pressReleaseVideo} key={i}>
                  <iframe
                    title="youtube"
                    src={(section.content as IframeEmbed).url}
                    width={(section.content as IframeEmbed).width}
                    height={(section.content as IframeEmbed).height}
                    frameBorder="0"
                  ></iframe>
                </div>
              );
            case "text":
            default:
              return (
                <Paragraph
                  key={i}
                  text={
                    typeof section.content === "string" ? section.content : ""
                  }
                  italic={section.italic}
                  bold={section.bold}
                  size="medium"
                  isCenterPhone="no"
                  color="black"
                  marginBottom="2rem"
                  marginTop={i !== 0 ? "2rem" : ".5rem"}
                />
              );
          }
        })}
        {/* <div className={styles.spotifyEmbedContainer}>
          <p>SPOTIFY EMBED</p>

          <div className={styles.spotifyEmbed}>
            <CopyToClipboardButton
              copied_translation={copied}
              color={colors && colors[0]}
              copy={copy}
              textToCopy="https://open.spotify.com/embed/album/0gpKaF4NMJv1u9L7GQ1eFy"
            />
          </div>
          <p>
            <i>{spotifyMessage} (12.03.2024).</i>
          </p>
        </div> */}
      </div>
    </div>
  );
};
