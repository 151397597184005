// videos.page.tsx

import React, { useState, memo } from "react";
import styles from "./videos.module.scss";
import { videoData, Video } from "../../productData";
import { Heading } from "../../components/Heading/heading.component";

const PlayIcon: React.FC = memo(() => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10 5L35 20L10 35V5Z" fill="white" />
  </svg>
));

function generateEmbedIframe(youtubeUrl?: string) {
  if (!youtubeUrl) {
    return <p>Video link is missing or incorrect.</p>;
  }

  const regex = /v=([^&]+)/;
  const match = youtubeUrl.match(regex);

  if (!match) {
    console.error("Invalid YouTube URL provided.");
    return <p>Invalid YouTube URL provided.</p>;
  }

  const videoId = match[1];
  const embedUrl = `https://www.youtube.com/embed/${videoId}?autoplay=1&showinfo=0&rel=0&modestbranding=1`;

  return (
    <iframe
      width="560"
      height="315"
      src={embedUrl}
      title="video"
      allow="autoplay; fullscreen"
      frameBorder="0"
    />
  );
}

const Videos: React.FC = () => {
  const [selectedVideo, setSelectedVideo] = useState<Video | null>(null);

  return (
    <div className={styles.videosContainer}>
      <div className={styles.videosContainerVideos}>
        <div className={styles.videosContainerTitle}>
          <Heading text="Videos" fontSize={3} />
        </div>
        {videoData.map((video: Video) => (
          <div key={video.id}>
            <button
              className={styles.videosContainerVideosVideo}
              onClick={() => setSelectedVideo(video)}
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setSelectedVideo(video);
                }
              }}
            >
              <div className={styles.videosContainerVideosVideoImage}>
                <img
                  src={video.image}
                  alt={`Thumbnail for ${video?.text || video.id}`}
                />
                <div className={styles.playIcon}>
                  <img
                    src={video.image}
                    alt={`Thumbnail for ${video?.text || video.id}`}
                  />
                  <PlayIcon />
                </div>
              </div>
              <div className={styles.videosContainerVideosVideoTitle}>
                <h3>{video.text}</h3>
              </div>
            </button>
          </div>
        ))}
      </div>

      {selectedVideo && (
        <div className={styles.modal}>
          <button
            className={styles.modalCross}
            onClick={() => setSelectedVideo(null)}
          >
            <img src="/images/close.png" alt="Close modal button" />
          </button>
          {generateEmbedIframe(selectedVideo.youtubeLink)}
        </div>
      )}
    </div>
  );
};

export default Videos;
