import React from "react";
import styles from "./BiographySection.module.scss";
import { Header } from "../Header/Header.component";
import { Paragraph } from "../Paragraph/Paragraph.component";

interface BiographySectionProps {
  title: string;
  text: string;
  image: string;
}

export const BiographySection: React.FC<BiographySectionProps> = ({
  title,
  text,
  image,
}) => {
  return (
    <div className={styles.bio}>
      <Header text={title} size="h2" textAlign="center" marginBottom="2rem" />
      <div className={styles.bioInfo}>
        <div className={styles.bioImage}>
          <img src={image} alt="Indieclimb" />
        </div>
        <div className={styles.bioInfoText}>
          <Paragraph text={text} size="small" isCenterPhone="yes" />
        </div>
      </div>
    </div>
  );
};
