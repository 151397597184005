import React, { CSSProperties, useState } from "react";
import styles from "./ControlPad.module.scss";

interface ControlPadProps {
  handleClick: () => void;
  isSpinning: boolean; // Add this line if you need isSpinning in ControlPad
  colors?: {
    primary: string;
    secondary: string;
    tertiary: string;
  };
}
interface CustomStyle extends CSSProperties {
  "--main-color"?: string;
  "--second-color"?: string;
  "--third-color"?: string;
}
const ControlPad: React.FC<ControlPadProps> = ({
  handleClick,
  isSpinning,
  colors,
}) => {
  const [isPlaying, setIsPlaying] = useState(!isSpinning);

  const handleButtonClick = () => {
    setIsPlaying(!isPlaying);
    handleClick();
  };

  const buttonClass = isPlaying
    ? `${styles.neoButton} ${styles.play}`
    : styles.neoButton;

  const getPseudoElementStyles = (): CustomStyle => {
    if (!colors) {
      return {};
    }

    const styles = {
      "--main-color": colors.primary,
      "--second-color": colors.secondary,
      "--third-color": colors.tertiary,
    };

    return styles;
  };
  return (
    <button
      className={buttonClass}
      onClick={handleButtonClick}
      style={getPseudoElementStyles()}
    ></button>
  );
};

export default ControlPad;
