const MEDIA_ASSETS_PATH = "../../images/icons/";
interface MediaItem {
  image: string;
  url: string;
  name?: string;
}

interface MediaConfig {
  [key: string]: MediaItem;
}

export const MEDIA_CONFIG: MediaConfig = {
  appleMusic: {
    image: `${MEDIA_ASSETS_PATH}APPLE_MUSIC.png`,
    url: "https://music.apple.com/no/artist/indieclimb/1273179739?l=nb",
    name: "Apple",
  },
  spotify: {
    image: `${MEDIA_ASSETS_PATH}SPOTIFY.png`,
    url: "https://spotify.link/dMt8ht1BCDb",
    name: "Spotify",
  },

  deezer: {
    image: `${MEDIA_ASSETS_PATH}DEEZER.png`,
    url: "https://www.deezer.com/fr/artist/13039421",
    name: "Deezer",
  },
  youtube: {
    image: `${MEDIA_ASSETS_PATH}YOUTUBE.png`,
    url: "https://www.youtube.com/@indieclimb",
    name: "Youtube",
  },
};

export const SOCIAL_MEDIA_CONFIG: MediaConfig = {
  instagram: {
    image: `${MEDIA_ASSETS_PATH}INSTAGRAM.png`,
    url: "http://instagram.com/indieclimb",
    name: "Instagram",
  },
  facebook: {
    image: `${MEDIA_ASSETS_PATH}FACEBOOK.png`,
    url: "https://www.facebook.com/Indieclimb/",
    name: "Facebook",
  },
};
